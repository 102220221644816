.youtube-card-cell{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2px 15px;
  background-color: #383E86;
  color: white;
  border-radius: 30px;
}

.youtube-card-plan{
  padding-top: 10px;
  padding-bottom: 3px;
  font-size: 15px;
}

.youtube-card-img{
  aspect-ratio: 2/1;
}