.icon-img-ions {
  object-fit: cover;  
  box-sizing: border-box;  
  cursor: pointer;
  border-radius: 50%;
}

.icon-img-ions.extra-small {
  width: 20px;
  height: 20px;
  font-size: 12px;
}

.icon-img-ions.small {
  width: 30px;
  height: 30px;
  font-size: 14px;
}

.icon-img-ions.medium {
  width: 40px;
  height: 40px;
  font-size: 18px;
}

.icon-img-ions.large {
  width: 45px;
  height: 45px;
  font-size: 20px;
}

.icon-img-ions.extra-large {
  width: 65px;
  height: 65px;
  font-size: 20px;
}

.user-icon-text-ions{
  background-color: rgb(170, 170, 170);
  font-weight: bold;
  color: white;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}