.crofun-calousel-with-home-title{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.crofun-calousel-with-title{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.crofun-calousel-title-more{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.crofun-calousel-bg-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-bottom: 0px;
  margin-top: 40px;
}

.crofun-calousel-button{
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  justify-content: center;
}

.crofun-calousel-bg{
  position: absolute;
  width: 100%;
  height: 70%;
  top: 40%;
  z-index: -1;
  /* background: linear-gradient(to right bottom, rgba(163, 127, 209, 0.25), rgba(12, 0, 116, 0.25)); */
  background-color: #383E86;
}

.request-carousel-item{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  cursor: pointer;
  pointer-events: all;
}

.top-banner-img{
  padding-right: 5px;
  padding-left: 5px
}
.top-banner-img.banner{
  padding-right: 0px;
  padding-left: 0px
}