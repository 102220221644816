.embla {
  width: 100%;
  height: 100%;
  margin: auto;
  --slide-height: 99%;
  /* これは0でいい。余白はcardWidthで調整すれば良い */
  --slide-spacing: 0px;
  /* これも0でいい。余白はcardHeightで調整すれば良い */
  --slide-size: 200px;
  /* --slide-size: 200px; */
}
.embla__viewport {
  overflow-y: hidden;
  overflow-x: hidden;
  padding-top: 15px;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* width が足りないと何故か無限ループできなくなるため、これ必須。長さを調整する必要ある。 */
  /* padding-left: var(--slide-spacing); */
  /* padding-bottom: var(--slide-spacing); */
}

.embla__slides_column {
  display: flex;
  flex-direction: column;
}

.banner__slides_column {
  display: flex;
  flex-direction: column;
}

.embla__slide__number {
  display: flex;
  justify-content: center;
  height: var(--slide-height);
}

.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 10px;
  margin: 50px 12%;
}

.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.0rem;
  align-items: center;

}

.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}

.embla__button:disabled {
  color: var(--detail-high-contrast);
}

.embla__play {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
}

@media (max-width: 460px) {
  .embla {
    --slide-spacing: 5px;
    /* --slide-size: 320px; */
    --slide-size: 0px;
  }

  .embla__controls {
    gap: 10px;
    margin: 40px 5%;
  }
  
}
