.divider-atoms {
  background-color: #E5E7EB;
  margin: 16px 0;
}

/* thickness variants */
.divider-atoms.thin {
  height: 0.5px;
}

.divider-atoms.normal {
  height: 1px;
}

.divider-atoms.thick {
  height: 2px;
}

/* color variants */
.divider-atoms.default {
  background-color: #E5E7EB;
}

.divider-atoms.gray {
  background-color: #9CA3AF;
}

.divider-atoms.primary {
  background-color: #383E86;
}

/* spacing variants */
.divider-atoms.dense {
  margin: 8px 0;
}

.divider-atoms.normal {
  margin: 16px 0;
}

.divider-atoms.loose {
  margin: 24px 0;
}