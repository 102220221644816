.text-atoms{
  color: #363636;
  font-weight: 450;
  white-space: pre-wrap;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.text-atoms:hover{
  cursor: text;
}

.text-atoms.black{
  color: #363636;
  /* color: white; */
}
.text-atoms.grey{
  color: rgb(110, 110, 110);
  /* color: white; */
}
.text-atoms.lightgrey{
  color: rgb(174, 173, 173);
}
.text-atoms.red{
  color: rgb(255, 34, 34);
  /* color: white; */
}
.text-atoms.purple{
  color: #383E86;
  /* color: white; */
}
.text-atoms.white{
  color: white;
}
.text-atoms.royalblue{
  color: royalblue;
  /* color: white; */
}

.text-atoms.weight-bold{
  font-weight: 700;
}
.text-atoms.weight-medium{
  font-weight: 600;
}
.text-atoms.weight-normal{
  font-weight: 500;
}
.text-atoms.weight-light{
  font-weight: 300;
}
.text-atoms.weight-extra-light{
  font-weight: 200;
}


.text-atoms.tiny {
  --font-size: 10px;
  --line-height: 1.1;
  letter-spacing: 0.2px;
}

.text-atoms.extra-small {
  --font-size: 14px;
  --line-height: 1.3;
  letter-spacing: 0.5px;
}

.text-atoms.small {
  --font-size: 16px;
  --line-height: 1.3;
  letter-spacing: 0.5px;
}

.text-atoms.normal {
  --font-size: 20px;
  --line-height: 1.3;
  letter-spacing: 0.5px;
}

.text-atoms.medium {
  --font-size: 24px;
  --line-height: 1.4;
  letter-spacing: 1px;
}

.text-atoms.large {
  --font-size: 30px;
  --line-height: 1.4;
  letter-spacing: 1.3px;
}

.text-atoms.bit-large {
  --font-size: 34px;
  --line-height: 1.4;
  letter-spacing: 1.3px;
}

.text-atoms.very-large {
  --font-size: 42px;
  --line-height: 1.4;
  letter-spacing: 1.4px;
}

.text-atoms.extra-large {
  --font-size: 58px;
  --line-height: 1.4;
  letter-spacing: 1.5px;
}
