.idea-img-ions{
  aspect-ratio: 5/3;
  border-radius: 5px;
  width: 100%;
}

.user-banner-img-ions{
  aspect-ratio: 9/3;
}

.idea-img-ions-wrapper {
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.request-img-ions{
  border: 1.5px solid lightgrey;
  aspect-ratio: 7/3;
  border-radius: 15px;
}

.idea-img-ions-delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 24px;
  color: red;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.idea-img-ions-delete-button:hover {
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
}

.idea-img-ions-added-container{
  position: absolute;
  top: -20px;
  right: -20px;
  border-radius: 50%;
  z-index: 10;
  /* background-color: rgba(255, 255, 255, 0.749); */
  width: 100px;
}

.idea-img-ions-added-img{
  width: 100%;
  height: 100%;
}