.banner {
  width: 100%;
  --slide-height: 19rem;
  --slide-spacing: 1rem; 
  --slide-size: 60%;
}

.banner__viewport {
  /* overflow: hidden; */
  overflow-x: hidden;
}

.banner__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}

.banner__slide {
  flex: 0 0 var(--slide-size);
  /* min-width: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: var(--slide-spacing);
}
.banner__slide__number {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner__controls {
  display: flex;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  margin-top: 1.8rem;
}

.banner__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.banner__dot {
  -webkit-tap-highlight-color: rgba(56, 62, 134);
  -webkit-appearance: none;
  appearance: none;
  background-color: #C2C2C2;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.banner__dot:after {
  /* background-color: #C2C2C2; */
  width: 1.0rem;
  height: 1.0rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.banner__dot--selected:after {
  background-color: #383E86;
}

@media (max-width: 767px) {
  /* スマートフォン用のスタイル */
  .banner {
    width: 100%;
    --slide-height: 19rem;
    --slide-spacing: 1rem; 
    --slide-size: 100%;
  }
  .banner__controls {
    margin-top: 0.8rem;
  }
  .banner__dot {
    margin: 0.2rem;
  }

  .banner__dot:after {
    width: 0.6rem;
    height: 0.6rem;
  }
  
  
 
}