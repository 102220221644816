.crofun-card-molecules-container{
  position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #2B1B41;
    background-color: white;
    border-radius: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    width: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* スムーズな拡大アニメーション */
}

.crofun-card-molecules-container.hover-effect{
  margin: 10px auto;
}

.crofun-card-molecules-container.hover-effect:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    transform: scale(1.04);
}

.crofun-card-molecules-img{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  pointer-events: none;
}

.crofun-card-molecules-main{
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  padding-top: 10px;
  width: 100%;
  pointer-events: none;
}

.crofun-card-molecules-header{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.crofun-card-molecules-tags{
  display: flex;  /* 必要 */
  gap: 5px;
  flex: 1;
  overflow-x: hidden;
  flex-wrap: nowrap;
  min-width: 0px;
}

.crofun-card-molecules-texts{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
  padding-bottom: 5px;
}

.crofun-card-molecules-progress{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.crofun-card-molecules-cell{
  display: flex;
  align-items: center;
  gap: 10px;
}